import React, { useState, useEffect } from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import product1 from "../images/product1.webp"
import Testimonials from "../components/Testimonials.js"
import Sucslider from "../components/success_slider.js"
import product_icon_1 from "../images/expertise.webp"
import product_icon_2 from "../images/quality.webp"
import product_icon_3 from "../images/turn-around.webp"
import product_icon_4 from "../images/product-photo-editing-tools.webp"
import product_img_2 from "../images/bottom-intro-img-product.webp"
import ep_img from "../images/product-photo-editing.webp"
import ep_img_1 from "../images/retouching-services.webp"
import ep_img_2 from "../images/image-clippings.webp"
import ep_img_3 from "../images/color-correctionss.webp"
import ep_img_4 from "../images/background-removal.webp"
import ep_img_5 from "../images/shadow-creation.webp"
import ep_img_6 from "../images/image-masking.webp"
import ep_img_7 from "../images/ghost-mannequin-editing.webp"
import ep_img_mob from "../images/pro-1-mob.webp"
import ep_img_1_mob from "../images/pro-1-mob.webp"
import ep_img_2_mob from "../images/pro-2-mob.webp"
import ep_img_3_mob from "../images/pro-3-mob.webp"
import ep_img_4_mob from "../images/pro-4-mob.webp"
import ep_img_5_mob from "../images/pro-5-mob.webp"
import product1_mob from "../images/product1-mob.webp"
import product_img_2_mob from "../images/product-img-2-mob.webp"
import { SuccessStoryData } from "../data/productSuccessStoryData.js"
import { TestimonialData } from "../data/productTestimonialData.js"
import setKey, { FirstCapital } from "../utils/setKey.js"
import { Trans, useI18next, Link } from "gatsby-plugin-react-i18next"
import { navigate, graphql } from "gatsby"

var Product_keyword = "Outsource Product Photo Editing"

const For = ({ location, ...rest }) => {
  const { languages, changeLanguage } = useI18next()

  const [dataKey, setDataKey] = useState("")
  const [locationKey, setLocationKey] = useState("")
  const [classname, setClassname] = useState("")

  useEffect(() => {
    typeof window !== "undefined" &&
    localStorage.getItem("gatsby-i18next-language") === "de"
      ? setClassname("de")
      : setClassname("")
  })

  useEffect(async () => {
    let data = await setKey(location, "utm_term_product", Product_keyword)
    if (data.length > 0) {
      setDataKey(data[0])
      setLocationKey(data[1])
    }

    return () => {
      data = null
    }
  }, [])

  useEffect(() => {
    if (locationKey === "Germany") {
      changeLanguage(languages[2])
    } else {
      if (
        rest.pageContext.language === "de" &&
        localStorage.getItem("location") !== "Germany"
      ) {
        localStorage.setItem("gatsby-i18next-language", "en")
        navigate("/outsource-product-photo-editing")
      }
    }
  }, [locationKey])

  return (
    <>
      <Header
        metaTitle={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Lagern Sie die Bearbeitung von Produktfotos aus, um die Attraktivität zu steigern"
            : "Outsource product photo editing for enhanced appeal"
        }
        metaDescription={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Steigern Sie die optische Attraktivität des Produkts mit unserer professionellen Note. Lagern Sie Produktfotobearbeitungsdienste aus, um erstklassige Qualität und auffällige Ergebnisse zu erzielen."
            : "Boost the product’s visual appeal with our professional touch. Outsource product photo editing services for superior quality and eye-catching results."
        }
        metakeyword={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Produktfotobearbeitung auslagern, Fotobearbeitungsdienste, Fotobearbeitungs-Outsourcing"
            : "Outsource Product Photo Editing, Photo Editing Services, Photo Editing Outsourcing"
        }
        canOnical="https://www.photoeditingoutsourcing.com/outsource-product-photo-editing/"
      />

      <div className="home-sec">
        <div className="zindex-div">
          <div className="product-sec">
            <div className="ps-first">
              <div className="container">
                <div className="home-first-sec">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className={`text-box ${
                          classname === "de" ? "text-box-german" : ""
                        }`}
                      >
                        {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") ===
                          "de" ? (
                          <h1>
                            <Trans>productpagetitle</Trans>
                          </h1>
                        ) : (
                          <h1>
                            {dataKey && dataKey
                              ? FirstCapital(dataKey)
                              : Product_keyword}
                          </h1>
                        )}

                       
                        {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <p>
                            <Trans>productpagetitledesc</Trans>
                          </p>
                        ) : (
                            <p>
                                <Trans>
                                Get Brilliant and Distinctive Product Photos while saving time, reducing costs, and increasing sales!
                                </Trans>
                            </p>
                          )}
                          
                        <div className="btn-home-sec">
                          <Link to="/contact-us" className="get-started">
                            <Trans>Get started</Trans>
                          </Link>
                          <Link to="/portfolio?tab=2" className="see-port">
                            <Trans>see portfolio </Trans>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="homeimg-box">
                        <img
                          src={product1}
                          alt="product-banner-img"
                          className="img-1 desktop-img-1"
                          width="488px"
                          height="578px"
                        ></img>
                        <img
                          src={product1_mob}
                          alt="product-banner-img-mob"
                          className="mob-img-1"
                          width="244px"
                          height="289px"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-3-sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-6 right-len">
                    <div className="row row-2">
                      <div className="col-md-6 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            <img
                              src={product_icon_1}
                              alt="product-icon-img-1"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <h1>
                            <Trans>Sachkenntnis</Trans>
                          </h1>
                          ) : (
                              <h1>
                              <Trans>Expertise</Trans>
                              </h1>
                            )}
                          {typeof window !== "undefined" &&
                             localStorage.getItem("gatsby-i18next-language") === "de" ? (
                            <p>
                              <Trans>Fundiertes Know-how in der Bearbeitung von Produktfotos.</Trans>
                            </p>
                            ) : (
                            <p>
                                <Trans>
                                  Deep expertise in product photo editing.
                                </Trans>
                            </p>
                            )}
                          
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            <img
                              src={product_icon_2}
                              alt="product-icon-img-2"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <h1>
                            <Trans>Qualität</Trans>
                          </h1>
                          ) : (
                              <h1>
                                <Trans>Quality</Trans>
                              </h1>
                            )}
                          
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <p>
                            <Trans>Garantiert hochwertige Bearbeitungen, die die Attraktivität des Produkts erhöhen.</Trans>
                          </p>
                          ) : (
                            <p>
                              <Trans>
                              Guaranteed high-quality edits that enhance product appeal.
                              </Trans>
                            </p>
                            )}
                          
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            <img
                              src={product_icon_3}
                              alt="product-icon-img-3"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <h1>
                            <Trans>Schnelle Bearbeitungszeit</Trans>
                          </h1>
                          ) : (
                              <h1>
                                <Trans>Quick Turnaround</Trans>
                              </h1>
                            )}
                          
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <p>
                            <Trans>Die schnelle Lieferung stellt sicher, dass Ihr Geschäftsbetrieb nicht unterbrochen wird.</Trans>
                          </p>
                          ) : (
                              <p>
                                <Trans>
                                Swift delivery ensures no disruption in your business operations.
                                </Trans>
                              </p>
                            )}
                          
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            <img
                              src={product_icon_4}
                              alt="product-icon-img-4"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <h1>
                            <Trans>Modernste Tools</Trans>
                          </h1>
                          ) : (
                              <h1>
                              <Trans>State-of-the-art Tools</Trans>
                              </h1>
                            )}
                          
                          {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                          <p>
                            <Trans>Einsatz modernster Technologien, einschließlich Adobe Photoshop und Lightroom.</Trans>
                          </p>
                          ) : (
                            <p>
                              <Trans>
                              Utilization of leading-edge technology, including Adobe Photoshop and Lightroom.
                              </Trans>
                            </p>
                            )}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 our-mission">
                    <div className="cat-slider-text">
                      <h4>
                        <Trans>EXCELLENCE</Trans>
                      </h4>
                      {typeof window !== "undefined" &&
                      localStorage.getItem("gatsby-i18next-language") ===
                        "de" ? (
                        <h2>
                          <Trans>productperfectiontext</Trans>
                        </h2>
                      ) : (
                        <h2>
                         Outsource Product Photo Editing and Elevate your Product Image Quality
                        </h2>
                      )}
                      {typeof window !== "undefined" &&
                        localStorage.getItem("gatsby-i18next-language") === "de" ? (
                        <p>
                          <Trans>Fotobearbeitung Outsourcing ist Ihr engagierter Partner für alle Ihre Produktbildbearbeitungsbedürfnisse. Wir sind darauf spezialisiert, Fotografen, Werbeagenturen, Immobilienagenturen und mehr dabei zu helfen, die zeitraubende Natur der Fotobearbeitung zu bewältigen und gleichzeitig eine gleichbleibende Qualität und kostengünstige Lösungen anzubieten.</Trans>
                        </p>
                      ) : (
                        <p>
                          <Trans>
                          Photo Editing Outsourcing is your dedicated partner for all your product image editing needs. We specialize in helping photographers, advertising agencies, real estate agencies, and more address the time-consuming nature of photo editing while offering ensuring consistent quality and providing cost-effective solutions. 
                          </Trans>
                        </p>
                        )}
                      
                      {typeof window !== "undefined" &&
                      localStorage.getItem("gatsby-i18next-language") ===
                        "de" ? (
                        <p>
                          <Trans>Wenn Sie die Bearbeitung von Produktfotos an uns auslagern, arbeiten Sie mit einem Unternehmen zusammen, das über die neuesten Trends auf dem Laufenden ist, jedes Bildvolumen handhabt und Aufgaben wie Hintergrundentfernung/Änderung, Farbkorrektur und Retusche kompetent ausführt.</Trans>
                        </p>
                      ) : (
                        <p>
                          <Trans>
                          When you outsource product photo editing to us, you partner with a company that stays up-to-date with the latest trends, can handle any volume of images, and expertly performs tasks such as background removal/change, color correction, and retouching. 
                          </Trans>
                        </p>
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="abot-2-sec secound-ab">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    {typeof window !== "undefined" &&
                      localStorage.getItem("gatsby-i18next-language") ===
                        "de" ? (
                        <p>
                          <Trans>Mit jahrelanger Erfahrung in der Branche, ist Fotobearbeitung Outsourcing verpflichtet, qualitativ hochwertige Fotobearbeitungsdienstleistungen für Unternehmen weltweit zur Verfügung zu stellen. Unser Team von qualifizierten Fachleuten verwendet die neuesten Werkzeuge und Techniken, um hervorragende Ergebnisse zu liefern und jedes Projekt auf die Bedürfnisse unserer Kunden zuzuschneiden.</Trans>
                        </p>
                      ) : (
                        <p>
                          <Trans>
                          With years of experience in the industry, Photo Editing Outsourcing is committed to providing high-quality photo editing services to businesses worldwide. Our team of skilled professionals uses the latest tools and techniques to deliver excellent results, tailoring each project to meet our clients' needs.
                          </Trans>
                        </p>
                      )}
                    {typeof window !== "undefined" &&
                      localStorage.getItem("gatsby-i18next-language") ===
                        "de" ? (
                        <p>
                          <Trans>Unsere Mission ist es, Unternehmen zu helfen, ihre Online-Präsenz zu verbessern, ihr Markenimage zu verbessern und den Umsatz durch hervorragende Produktbilder zu steigern Vertrauen Sie uns, Ihre Produktfotos in leistungsstarke Verkaufstools zu verwandeln.</Trans>
                        </p>
                      ) : (
                        <p>
                          <Trans>
                          Our mission is to help businesses enhance their online presence, improve their brand image, and increase sales through superior product images. Trust us to transform your product photos into powerful selling tools.
                          </Trans>
                        </p>
                      )}
                    
                  </div>
                  <div className="col-md-6">
                    <div className="img-box-about">
                      <img
                        src={product_img_2}
                        alt="product-img-2"
                        className="desktop-img-1"
                        width="490px"
                        height="417px"
                      ></img>
                      <img
                        src={product_img_2_mob}
                        alt="product-img-2-mob"
                        className="mob-img-1"
                        width="333px"
                        height="250px"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ep-sec">
              <div className="container">
                <div className="cat-slider-text">
                  {typeof window !== "undefined" &&
                  localStorage.getItem("gatsby-i18next-language") === "de" ? (
                    <h4>
                      <Trans>ECOMMERCE-SERVICES-PRODUCT</Trans>
                    </h4>
                  ) : (
                    <h4>Perfection in Every Picture </h4>
                  )}
                  <h2
                    className={`product-photo-service ${
                      classname === "de" && "product-photo-service-german"
                    }`}
                  >
                    <Trans>
                    Transform Images and Amplify Sales with High-Caliber Photo Editing
                    </Trans>
                  </h2>
                  <p>
                    <Trans>
                    Outsource product photo editing services with us and achieve enhanced product visuals. 
                    </Trans>
                  </p>
                </div>
                <div className="row">
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img}
                        alt="eCommerce-Product-img"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_mob}
                        alt="eCommerce-Product-img-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    {typeof window !== "undefined" &&
                    localStorage.getItem("gatsby-i18next-language") === "de" ? (
                      <h1>
                        <Trans>Photo Retouching Product</Trans>
                      </h1>
                    ) : (
                      <h1>Product Photo Editing</h1>
                    )}
                    <p>
                      <Trans>
                      Get high-resolution pictures that help boost your product sales.
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_1}
                        alt="eCommerce-Product-img-1"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_1_mob}
                        alt="eCommerce-Product-img-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    <h1>
                      <Trans>Retouching Services</Trans>
                    </h1>
                    <p>
                      <Trans>
                      Effortlessly remove flaws and enhance product photos for aesthetically pleasing images.
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_2}
                        alt="eCommerce-Product-img-2"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_2_mob}
                        alt="eCommerce-Product-img-2-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    {typeof window !== "undefined" &&
                    localStorage.getItem("gatsby-i18next-language") === "de" ? (
                      <h1>
                        <Trans>Background Removal Product</Trans>
                      </h1>
                    ) : (
                      <h1>Image Clipping</h1>
                    )}
                    <p>
                      <Trans>
                      Isolate the product from the background and enhance the focus and appeal of the image.
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_3}
                        alt="eCommerce-Product-img-3"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_3_mob}
                        alt="eCommerce-Product-img-3-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    <h1>
                      <Trans>Color Correction</Trans>
                    </h1>
                    <p>
                      <Trans>
                      Correct color discrepancies and ensure uniformity in images 
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_4}
                        alt="eCommerce-Product-img-4"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_4_mob}
                        alt="eCommerce-Product-img-4-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    <h1>
                      <Trans>Background Removal</Trans>
                    </h1>
                    <p>
                      <Trans>
                      Remove distracting backgrounds to make the product stand out.
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_5}
                        alt="eCommerce-Product-img-5"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_5_mob}
                        alt="eCommerce-Product-img-5-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    <h1>
                      <Trans>Shadow Creation</Trans>
                    </h1>
                    <p>
                      <Trans>
                      Create natural-looking shadows to add depth, making the images realistic.
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_6}
                        alt="eCommerce-Product-img-5"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_5_mob}
                        alt="eCommerce-Product-img-5-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    <h1>
                      <Trans>Image Masking</Trans>
                    </h1>
                    <p>
                      <Trans>
                      Separate the product from backgrounds or other elements in the image.
                      </Trans>
                    </p>
                  </div>
                  <div className="ep-box">
                    <div className="ep-img">
                      <img
                        src={ep_img_7}
                        alt="eCommerce-Product-img-5"
                        className="desktop-img-1"
                        width="296px"
                        height="359px"
                      ></img>
                      <img
                        src={ep_img_5_mob}
                        alt="eCommerce-Product-img-5-mob"
                        className="mob-img-1"
                        width="236px"
                        height="286px"
                      ></img>
                    </div>
                    <h1>
                      <Trans>Ghost Mannequin Editing</Trans>
                    </h1>
                    <p>
                      <Trans>
                      Remove the mannequin from your product images, giving them a 3D, hollow man effect
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="how-sec">
              <div className="container">
                <h2>
                  <Trans>Photo Editing Outsourcing</Trans>
                </h2>
                {typeof window !== "undefined" &&
                localStorage.getItem("gatsby-i18next-language") === "de" ? (
                  <p className="how-germen-para-text">
                    <Trans>ProductHowtoWorkDesc1</Trans>
                  </p>
                ) : (
                  <p>
                    Outsource your product photo editing and benefit from enhanced image appeal 
                  </p>
                )}
              </div>
            </div>

            <div className="category-slider-sec testimonials-sec ecommerce-testi">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="cat-slider-text">
                      <h4>
                        <Trans>Word of praise</Trans>
                      </h4>
                      <h3>
                        <Trans>Testimonials</Trans>
                      </h3>
                      <p>
                        <Trans>
                          Don’t just take our word for it. Discover what our
                          customers have to say about us.
                        </Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
              <div className="main-slider">
                <div className="container cate-slider">
                  <Testimonials
                    locationKey={locationKey}
                    TestimonialData={TestimonialData}
                  />
                </div>
              </div>
            </div>

            <div className="Success-sec">
              <div className="container">
                <div className="cat-slider-text">
                  <h4>
                    <Trans>Success</Trans>
                  </h4>
                  <h3>
                    <Trans>Success Stories</Trans>
                  </h3>
                  <p>
                    <Trans>
                      Delivering true value to every customer. Find out how we
                      do it.
                    </Trans>
                  </p>
                </div>

                <div className="main-slider">
                  <div className="container cate-slider">
                    <Sucslider
                      locationKey={locationKey}
                      SuccessStoryData={SuccessStoryData}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <GetStart
              dataKey={FirstCapital(dataKey)}
              pageKeyword={Product_keyword}
            /> */}
            <div className="start-sec">
              <div className="container">
                <div className="row  align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="start-text">
                        
                          {typeof window !== "undefined" &&
                          localStorage.getItem("gatsby-i18next-language") === "de" ? (
                            <h1>
                              <Trans>Outsourcing Produkt Fotobearbeitungsdienste</Trans>
                            </h1>
                          ) : (
                            <h1>
                              <Trans>Outsource Product Photo Editing Services</Trans>
                            </h1>
                          )}
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="start-btn">
                      <Link to="/contact-us">
                        <button>
                          <Trans>Get started</Trans>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default For

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
